import Link from "next/link";
import Image from "next/image";
import mochalogo from "../public/mochalogo.webp";
import { useRouter } from "next/router";
import { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes, faUser, faVideo } from "@fortawesome/free-solid-svg-icons";






const Navbar = () => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [isFeaturesOpen, setIsFeaturesOpen] = useState(false);
  const [isResourcesOpen, setIsResourcesOpen] = useState(false);
  const [isAboutUsOpen, setIsAboutUsOpen] = useState(false);

  const { pathname } = useRouter();

  const toggleCollapse = () => setIsCollapsed(!isCollapsed);
  const toggleFeatures = () => setIsFeaturesOpen(!isFeaturesOpen);
  const toggleResources = () => setIsResourcesOpen(!isResourcesOpen);
  const toggleAboutUs = () => setIsAboutUsOpen(!isAboutUsOpen);

  const isActive = (path) => pathname === path;

  return (
    <nav className="w-full fixed bg-white z-10 border-b border-[#DBDBDB]">
      <div className="mx-auto px-4 sm:px-6 lg:px-8 flex justify-between items-center py-4">

        <Link href="/">
          <span>
            <Image src={mochalogo}
              alt="Logo" width={140} height={40}
              className="cursor-pointer gap-[10px]"
              loading="lazy"
            />
          </span>
        </Link>


        <button
          className="lg:hidden text-black focus:outline-none w-5 h-5 flex items-center justify-center "
          onClick={toggleCollapse}

          aria-label={isCollapsed ? 'Open menu' : 'Close menu'}
          aria-expanded={!isCollapsed}
        >
          <FontAwesomeIcon icon={isCollapsed ? faBars : faTimes} className="text-2xl" />
        </button>


        <ul className="hidden lg:flex space-x-8 items-center font-poppins text-[14px] font-[600] leading-[22px]">
          <li className={`hover:text-green-700 ${isActive("/") ? "text-green-700" : ""}`}>
            <Link href="/">HOME</Link>
          </li>


          <li className="relative group">
            <Link href="#" className="hover:text-green-700">
              FEATURES
            </Link>
            <div className="absolute left-0 hidden group-hover:block bg-white shadow-lg">
              <ul className="space-y-2 py-2 px-5 m-auto">
                <li className="hover:bg-gray-100 hover:text-green-700"><Link href="/features/invoicing">Invoicing</Link></li>
                <li className="hover:bg-gray-100 hover:text-green-700"><Link href="/features/sales">Sales</Link></li>
                <li className="hover:bg-gray-100 hover:text-green-700 whitespace-nowrap"><Link href="/features/purchaseorders">Purchase Orders</Link></li>
                <li className="hover:bg-gray-100 hover:text-green-700"><Link href="/features/inventory">Inventory</Link></li>
                <li className="hover:bg-gray-100 hover:text-green-700"><Link href="/features/expenses">Expenses</Link></li>
                <li className="hover:bg-gray-100 hover:text-green-700"><Link href="/features/bills">Bills</Link></li>
                <li className="hover:bg-gray-100 hover:text-green-700"><Link href="/features/banking">Banking</Link></li>
                <li className="hover:bg-gray-100 hover:text-green-700"><Link href="/features/reporting">Reporting</Link></li>
              </ul>
            </div>
          </li>

          <li className={`hover:text-green-700 ${isActive("/pricing") ? "text-green-700" : ""}`}>
            <Link href="/pricing">PRICING</Link>
          </li>


          <li className="relative group">
            <Link href="#" className="hover:text-green-700">
              RESOURCES
            </Link>
            <div className="absolute left-0 hidden group-hover:block bg-white shadow-lg">
              <ul className="space-y-2 py-2 px-5">
                <li className="hover:bg-gray-100 hover:text-green-700" ><Link href="/resources/blogs">Blogs</Link></li>
                <li className="hover:bg-gray-100 hover:text-green-700 whitespace-nowrap"><Link href="/resources/helpcenter">Help Center</Link></li>
              </ul>
            </div>
          </li>


          <li className="relative group">
            <Link href="#" className="hover:text-green-700">
              ABOUT US
            </Link>
            <div className="absolute left-0 hidden group-hover:block bg-white shadow-lg -ml-2">
              <ul className="space-y-2 py-2 px-5">
                <li className="hover:bg-gray-100 hover:text-green-700 whitespace-nowrap"><Link href="/aboutus/company">Vision & Values</Link></li>
                <li className="hover:bg-gray-100 hover:text-green-700"><Link href="/aboutus/ourteam">Our Team</Link></li>
              </ul>
            </div>


          </li>

          <li className={`hover:text-green-700 ${isActive("/contact") ? "text-green-700" : ""}`}>
            <Link href="/contact">CONTACT US</Link>
          </li>


          <li>
            <Link href="https://app.mochaaccounting.com/register">
              <button className="border border-green-700 text-green-700 rounded-full px-[20px] py-[12px] flex items-center justify-center text-sm transition-transform duration-150 transform active:scale-95">
                <FontAwesomeIcon icon={faUser} className="mr-2 w-3" />
                Sign Up for Free
              </button>
            </Link>
          </li>
          <li>
            <Link href="https://app.mochaaccounting.com/login">
              <button className="border bg-[#006241] text-white rounded-full px-[20px] py-[12px] flex items-center justify-center text-sm transition-transform duration-150 transform active:scale-95">
                <FontAwesomeIcon icon={faUser} className="mr-2 w-3" />
                Login
              </button>
            </Link>
          </li>
          <li>
            <Link href="/scheduledemo">
              <button className="bg-[#1E3932] text-white rounded-full px-[20px] py-[12px] flex items-center justify-center text-sm transition-transform duration-150 transform active:scale-95">
                Schedule a Demo
                <FontAwesomeIcon icon={faVideo} className="ml-2 w-5" />
              </button>
            </Link>
          </li>
        </ul>
      </div>

      <div
        className={`lg:hidden bg-white w-full fixed left-0 top-16 transition-all duration-300 ease-in-out ${isCollapsed ? "max-h-0 overflow-hidden" : "max-h-screen"
          }`}
      >
        <ul className="flex flex-col space-y-4 p-4">
          <li>
            <Link href="/" onClick={toggleCollapse}>HOME</Link>
          </li>


          <li>
            <button onClick={toggleFeatures} className="w-full text-left">
              FEATURES

            </button>
            {isFeaturesOpen && (
              <ul className="pl-4 mt-2 space-y-2">
                <li className="hover:bg-gray-100 hover:text-green-700"><Link href="/features/invoicing" onClick={toggleCollapse}>Invoicing</Link></li>
                <li className="hover:bg-gray-100 hover:text-green-700"><Link href="/features/sales" onClick={toggleCollapse}>Sales</Link></li>
                <li className="hover:bg-gray-100 hover:text-green-700"><Link href="/features/purchaseorders" onClick={toggleCollapse}>Purchase Orders</Link></li>
                <li className="hover:bg-gray-100 hover:text-green-700"><Link href="/features/inventory" onClick={toggleCollapse}>Inventory</Link></li>
                <li className="hover:bg-gray-100 hover:text-green-700"><Link href="/features/expenses" onClick={toggleCollapse}>Expenses</Link></li>
                <li className="hover:bg-gray-100 hover:text-green-700"><Link href="/features/bills" onClick={toggleCollapse}>Bills</Link></li>
                <li className="hover:bg-gray-100 hover:text-green-700"><Link href="/features/banking" onClick={toggleCollapse}>Banking</Link></li>
                <li className="hover:bg-gray-100 hover:text-green-700"><Link href="/features/reporting" onClick={toggleCollapse}>Reporting</Link></li>
              </ul>
            )}
          </li>


          <li>
            <Link href="/pricing" onClick={toggleCollapse}>PRICING</Link>
          </li>


          <li>
            <button onClick={toggleResources} className="w-full text-left">
              RESOURCES

            </button>
            {isResourcesOpen && (
              <ul className="pl-4 mt-2 space-y-2">
                <li><Link href="/resources/blogs" onClick={toggleCollapse}>Blogs</Link></li>
                <li><Link href="resources/helpcenter" onClick={toggleCollapse}>Help Center</Link></li>
              </ul>
            )}
          </li>


          <li>
            <button onClick={toggleAboutUs} className="w-full text-left">
              ABOUT US

            </button>
            {isAboutUsOpen && (
              <ul className="pl-4 mt-2 space-y-2">
                <li><Link href="#" onClick={toggleCollapse}>Vision & Values</Link></li>
                <li><Link href="/aboutus/ourteam" onClick={toggleCollapse}>Our Team</Link></li>
              </ul>
            )}
          </li>


          <li>
            <Link href="/contact" onClick={toggleCollapse}>CONTACT US</Link>
          </li>


          <li>
            <Link href="https://app.mochaaccounting.com/register">
              <button className=" w-full border border-green-700 text-green-700 rounded-full px-[20px] py-[12px] flex items-center justify-center text-sm">
                <FontAwesomeIcon icon={faUser} className="mr-2 w-5" />
                Sign Up for Free
              </button>
            </Link>
          </li>
          <li>
            <Link href="https://app.mochaaccounting.com/login">
              <button className="w-full border bg-[#006241] text-white rounded-full px-[20px] py-[12px] flex items-center justify-center text-sm">
                <FontAwesomeIcon icon={faUser} className="mr-3 w-5" />
                Login
              </button>
            </Link>
          </li>

          <li>
            <Link href="/scheduledemo">
              <button className="w-full bg-[#1E3932] text-white rounded-full px-[20px] py-[13px] flex items-center justify-center text-sm">
                Schedule a Demo
                <FontAwesomeIcon icon={faVideo} className="ml-2 w-5" />
              </button>
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;

